import React from 'react'
import {FaFacebookSquare, FaInstagramSquare, FaTwitterSquare} from 'react-icons/fa'
import './style.css'

const LandingPage = () => {
  return (
    <section className="home">
        <div className="container">
        <div className='content'>
            <h1>Wonderful.<br></br><span>Boutique</span></h1>
            <p>Paradise Point is a small-scale boutique hotel with four beautiful cozy stylish rooms.</p>
            <a href="#readmore">Read More</a>
        </div>
        </div>
        <div className="media-icons">
            <a href="#"> <FaFacebookSquare/></a>
            <a href="#"> <FaInstagramSquare/></a>
            <a href="#"> <FaTwitterSquare/></a>
        </div>
    </section>
  )
}

export default LandingPage