import React from 'react'
import './style.css'
import {FaFacebookSquare, FaInstagramSquare, FaMarkdown, FaTwitterSquare} from 'react-icons/fa'
import Logo from '../../images/logo.png'
import {FaMapMarkerAlt, FaPhone, FaEnvelope} from 'react-icons/fa'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div className="footer-distributed">

			<div className="footer-left">

				<a><img src={Logo} alt="ParadisePoint" /></a>

				<p className="footer-links">
					<Link to="/" className="link-1">Home</Link>
				
					<Link to="/about">About Us</Link>
					
					<Link to="/rooms">Rooms</Link>
					
					<Link to="/contact" >Contact Us</Link>
				</p>

				<p className="footer-company-name">developed by © donaldothedev</p>
			</div>

			<div className="footer-center">

				<div className='location'>
					 <div className='svg-icons'><FaMapMarkerAlt/> </div>
					<p><span>7 Street 6 Deshmoret 54</span>  Korce, Albania</p>
				</div>

				<div className='location'>
					<div className="svg-icons"><FaPhone /></div>
					<p><a className="mail" href="tel:067-5210-839">+355 067 5210 839</a></p>
				</div>

				<div className='location'>
				    <div className="svg-icons"><FaEnvelope /></div>
					<p><a className="mail" href="mailto:boutiqueparadisepoint@gmail.com">boutiqueparadisepoint@gmail.com</a></p>
				</div>

			</div>

			<div className="footer-right">

				<p className="footer-company-about">
					<span>Follow us in our social media !</span>
				</p>

				<div className="footer-icons">

					<a href="#"><FaFacebookSquare /></a>
					<a href="#"><FaInstagramSquare /></a>
					<a href="#"><FaTwitterSquare /></a>

				</div>

			</div>

		</div>
  )
}

export default Footer